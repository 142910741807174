<template>
  <div>
    <div class="container-fluid px-0">
      <div class="px-0">
        <Header></Header>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-9">
          <slot></slot>
        </div>

        <div
          class="col-12 col-lg-3 d-lg-block"
          :class="{ 'd-none': $route.name !== 'Home' }"
        >
          <Sidebar></Sidebar>
        </div>
      </div>
    </div>
    <div
      class="
        d-lg-none
        bg-white
        border-0
        d-flex
        justify-content-center
        align-items-center
        gap-2
      "
    >
      <button
        v-if="$route.name === 'Home'"
        class="
          button-pc
          text-success
          my-3
          h4
          bg-white
          font-weight-bold
          border-radius-lg
          bg-white
          font-weight-bold
          text-uppercase
        "
        @click="togglePCMode"
      >
        {{ $t("footer.PC display") }}
      </button>
    </div>
    <div
      class="
        d-lg-none d-flex
        bg-white
        border-0
        justify-content-end
        align-items-center
        cursor-pointer
        mb-3
      "
      style="padding-right: 20px"
    >
      <span
        @click="scroll"
        class="bg-success text-white rounded-circle px-1 py-0 mx-2 btn mt-2"
      >
        <i class="fas fa-chevron-up fa-2x"></i>
      </span>
      <span class="text-black"> {{ $t("footer.to the top") }}</span>
    </div>
    <div class="container-fluid px-0 footer__wrapper">
      <div class="p-0">
        <Footer></Footer>
      </div>
      <footer>
        <p class="text-white bg-success text-center small p-2 m-0">
          Copyright ©2021 What's Up Danang. All Rights Reserved.
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
import Header from "../../layout/header/MainHeader.vue";
import Footer from "../../layout/footer/Footer.vue";
import Sidebar from "../sidebar/Sidebar.vue";
export default {
  name: "LargeLayout",
  components: { Header, Footer, Sidebar },
  methods: {
    scroll() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    togglePCMode() {
      var viewport = "width=1024";
      document.getElementsByTagName("meta")["viewport"].content = viewport;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer__wrapper {
  background-position: bottom right;
  background: url("../../assets/footer/m_footer.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 2px solid var(--success);
}
@media screen and (min-width: 992px) {
  .footer-wrapper {
    font-size: 11px;
    background: url("../../assets/footer/footer.jpg");
    border-top: 2px solid var(--success);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;
  }
}
@media screen and (max-width: 992px) {
  .col-12 {
    padding: 0 8px;
  }
}
</style>
