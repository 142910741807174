<template>
  <div>
    <VueSlickCarousel v-bind="settings" class="slick-carousel">
      <div v-for="(image, index) in carousels" :key="index" class="w-100">
        <img :src="image.src" alt="image" class="carousel__image" />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      slide: 0,
      sliding: null,
      settings: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 5000,
        slidesToShow: 1,
        autoplay: true,
        fade: true,
      },
    };
  },
  computed: {
    carousels() {
      return this.$store.getters.getCarousels.map((image) => {
        return {
          src: this.url + image,
        };
      });
    },
  },
  mounted() {
    this.$store.dispatch("fetchCarousels");
  },
};
</script>
<style lang="scss" scoped>
.slick-carousel {
  @media screen and(min-width: 1024px) {
    max-height: 312px;
  }
  @media screen and(min-width: 1025px) {
    min-height: 520px;
  }

  @media screen and(max-width: 767px) {
    min-height: 300px;
  }
}
::v-deep .carousel-item {
  transition: opacity 3000ms ease-in-out;
}
.carousel__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
  @media screen and(min-width: 1025px) {
    max-height: 312px;
  }
  @media screen and(min-width: 1025px) {
    min-height: 520px;
  }
  @media screen and(max-width: 767px) {
    min-height: 300px;
  }
}
@media screen and(max-width: 1024px) {
  ::v-deep .img-fluid {
    min-height: 200px;
    object-fit: cover;
  }
}
</style>
