<template>
  <div class="header">
    <!-- Header nav only for small-screen devices -->
    <MobileHeaderNav />

    <!-- Image and description for any device -->
    <div class="d-flex flex-column">
      <div class="py-2 px-2 header-text fs-5 d-none d-lg-block">
        {{ $t("header.description") }}
      </div>
      <div class="position-relative">
        <router-link to="/" class="position-absolute logo d-none d-lg-block">
          <img
            src="@/assets/header/logo.png"
            alt="logo"
            class="w-100 mt-4 mx-4"
            style="max-width: 400px"
          />
        </router-link>
        <Carousel />
      </div>
    </div>
  </div>
</template>
<script>
import MobileHeaderNav from "./components/MobileHeaderNav.vue";
import Carousel from "./components/Carousel.vue";
export default {
  name: "header-component",
  components: { MobileHeaderNav, Carousel },
};
</script>

<style lang="scss" scoped>
.logo {
  z-index: 1000;
}
a,
a:hover,
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

.header {
  --bs-gutter-x: 0;
  padding: 0 0 20px 0;
}

@media screen and (min-width: 992px) {
  .header-text {
    text-align: center;
    color: white;
    background-color: var(--success);
  }
}
</style>
